import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { lastValueFrom } from 'rxjs';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appTranslateInitializerFactory(translate: TranslateService, injector: Injector): object {
  return async (): Promise<void> => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    translate.setDefaultLang('ro');
    try {
      await lastValueFrom(translate.use(translate.defaultLang));
    } catch (err) {
      console.error(err);
    }
  };
}
