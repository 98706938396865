import { APP_INITIALIZER, Injector, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeRO from '@angular/common/locales/ro';
import localeEN from '@angular/common/locales/en';
import localeTR from '@angular/common/locales/tr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { appTranslateInitializerFactory, createTranslateLoader } from '@app/core/helpers/translation-functions';
import { MentenoMissingTranslationHandler } from '@app/core/helpers/translation-handler';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptorService } from '@app/core/interceptors/auth-interceptor.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@env/environment';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
registerLocaleData(localeRO);
registerLocaleData(localeEN);
registerLocaleData(localeTR);

export function tokenGetter(): string {
  return localStorage.getItem('token') as string;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MentenoMissingTranslationHandler },
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10000'
    }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appTranslateInitializerFactory, deps: [TranslateService, Injector], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'ro-RO' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
