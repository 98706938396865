export interface IApiDropdownItem {
  id: string | number;
  name: string;
}

export class DropdownItem {
  label = '';
  value: string | number = '';

  constructor(dto?: IApiDropdownItem) {
    if (!dto) {
      return
    }
    this.label = dto.name;
    this.value = dto.id;
  }
}
