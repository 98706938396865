import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { WorkerService } from '@app/core/services/worker/worker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private config: PrimeNGConfig,
    private ws: WorkerService,
    private translateService: TranslateService,
    private afMessaging: AngularFireMessaging
  ) {
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang('ro');
    this.requestPermission();
    this.listenForMessages();
  }

  translate(lang: string): void {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  requestPermission() {
    this.afMessaging.requestPermission.subscribe({
      next: () => {
        console.log('Permission granted!')
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  listenForMessages() {
    this.afMessaging.messages.subscribe(
      (message) => {
        this.showCustomNotification(message);
        this.ws.refreshStats = new Date();
      }
    );
  }

  showCustomNotification(payload: any) {
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
    } else if (Notification.permission === "granted") {
      // If permission is already granted
      const notification = new Notification("", {
        body: payload.notification?.body || 'You have a new message!',
        icon: payload.notification?.image || 'https://dev.menteno.com/assets/img/logo_vertical.svg'
      });
    } else if (Notification.permission !== "denied") {
      // If permission isn't denied, request it
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification(payload.notification?.title || '', {
            body: payload.notification?.body || 'You have a new message!',
            icon: payload.notification?.image || 'https://dev.menteno.com/assets/img/logo_vertical.svg'
          });
        }
      });
    }
  }
}
