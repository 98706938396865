import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@app/core/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./onboarding/auth-wrapper/auth-wrapper.module').then(m => m.AuthWrapperModule)
  },
  /**
   * We need the refresh route to refresh when the onboarding is complete.
   */
  {
    path: 'refresh',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: 'confirmation',
    loadComponent: () => import('@app/onboarding/account-confirmation/account-confirmation.component').then(mod => mod.AccountConfirmationComponent)
  },
  {
    path: 'guest',
    loadComponent: () => import('@app/onboarding/guest/guest.component').then(mod => mod.GuestComponent)
  },
  {
    path: 'worker',
    loadChildren: () => import('@app/worker/worker.module').then(m => m.WorkerModule)
  },
  {
    path: 'auth-wrapper',
    loadChildren: () => import('./onboarding/auth-wrapper/auth-wrapper.module').then(m => m.AuthWrapperModule)
  },
  {
    path: '**',
    redirectTo: '/not-found',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
