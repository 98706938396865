import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@app/core/services/auth/auth.service';

export const authGuard: CanActivateFn = (_: ActivatedRouteSnapshot, __: RouterStateSnapshot) => {
  const authService: AuthService = inject(AuthService);

  if (authService.getAuthToken()) {
    return true;
  }
  authService.logout();
  return false;
};
