import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpResponse,
  HttpProgressEvent,
  HttpUserEvent, HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private authHeader = 'Authorization';

  constructor(
    private authService: AuthService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getAuthToken();
    const skippedUrls = ['guest','register', 'authenticate', 'assets', 'forgot-password', 'reset-password', 'complete-registration', 'user-token']
      .filter((url: string) => req.url.includes(url));

    if (!token) {
      if (skippedUrls.length) {
        return next.handle(req).pipe(
          catchError((err: HttpErrorResponse): Observable<never> => {
            return throwError(() => err);
          })
        );
      }
      this.authService.logout();
    }

    req = this.addAuthenticationToken(req, token);

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse): Observable<HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>> => {
        if (err && err.status === 401) {
          this.authService.logout();
        }

        return throwError(() => err);
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string | any): HttpRequest<HttpHeaders> {
    return request.clone({
      headers: request.headers.set(this.authHeader, `Bearer ${token}`)
    });
  }
}
