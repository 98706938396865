import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { IStatus } from '@app/core/models/status/status.model';
import { WorkOrderStatus } from '@app/core/enums/work-order-status.enum';
import { IDatatable } from '@app/core/models/data-table/data-table.model';
import { IWorkOrderDetails } from '@app/core/models/work-order-details/work-order-details.model';
import { DropdownItem, IApiDropdownItem } from '@app/core/models/dropdown/dropdown-item.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class WorkerService {
  private refreshStats$: Subject<Date> = new Subject<Date>();
  onRefreshStats: Observable<Date> = this.refreshStats$.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }

  set refreshStats(date: Date) {
    this.refreshStats$.next(date);
  }

  getWorkOrders(status: WorkOrderStatus, params?: HttpParams): Observable<IDatatable<IWorkOrderDetails>> {
    return this.http.get<IDatatable<IWorkOrderDetails>>(`${environment.BASE_URL}/m/work-orders?status=${status}`, {params})
  }

  getWorkOrder(id: number, params?: HttpParams): Observable<IWorkOrderDetails> {
    return this.http.get<IWorkOrderDetails>(`${environment.BASE_URL}/m/work-orders/${id}`, {params})
  }

  getCurrentStatus(params?: HttpParams): Observable<IStatus> {
    return this.http.get<IStatus>(`${environment.BASE_URL}/m/status`, {params})
  }

  getCategoriesByWorkOrder(id: string, query?: HttpParams): Observable<Array<DropdownItem>> {
    return this.http.get<Array<IApiDropdownItem>>(`${environment.BASE_URL}/m/work-order/${id}/categories/search`,
      {params: query}).pipe(map(this.mapDropdownItems));
  }

  startWorkOrder(id: number): Observable<unknown> {
    return this.http.put(`${environment.BASE_URL}/m/work-orders/${id}/start`, {})
  }

  finishWorkOrder(id: number, comment: string): Observable<unknown> {
    return this.http.put(`${environment.BASE_URL}/m/work-orders/${id}/resolve`, {comment})
  }

  updateWorkOrderTeam(woId: number, team: string): Observable<unknown> {
    return this.http.put(`${environment.BASE_URL}/m/work-orders/${woId}/team`, {team})
  }

  updateWorkOrderCategory(woId: number, catId: number): Observable<unknown> {
    return this.http.put(`${environment.BASE_URL}/m/work-orders/${woId}/category/${catId}`, {})
  }

  changeWorkOrderPriority(woId: number, priority: string): Observable<unknown> {
    return this.http.put(`${environment.BASE_URL}/m/work-orders/${woId}/change-priority`, {
      priority
    })
  }

  private mapDropdownItems = (resp: Array<IApiDropdownItem>) => {
    return resp.map((i: IApiDropdownItem) => new DropdownItem(i));
  };
}
