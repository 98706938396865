import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { EMPTY, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { UserRole } from '@app/core/enums/user-role.enum';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  static readonly adminRoles: Array<string> = [UserRole.SuperAdmin, UserRole.Administrator, UserRole.TeamLead];
  static readonly userRoles: Array<string> = [UserRole.User];
  static readonly USER_ACCESS_TOKEN_KEY = 'token';
  static readonly USER_ROLE = 'role';

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtService: JwtHelperService) {
  }

  getAuthToken(): string | null {
    return localStorage.getItem(AuthService.USER_ACCESS_TOKEN_KEY);
  }

  setAuthToken(token: string): void {
    localStorage.setItem(AuthService.USER_ACCESS_TOKEN_KEY, token);
  }

  authenticate(credentials: { username: string, password: string }): Observable<object> {
    return this.http.post<any>(`${environment.BASE_URL}/authenticate`, credentials).pipe(map(this.storeTokenData));
  }

  forgotPassword(email: string): Observable<object> {
    return this.http.post(`${environment.BASE_URL}/forgot-password?email=${email}`, {});
  }

  resetPassword(credentials: { token: string, password: string, passwordConfirmation: string }): Observable<object> {
    return this.http.post(`${environment.BASE_URL}/reset-password`, credentials);
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['auth']).then((): void => {
    });
  }

  private storeTokenData = (data: { token: string }): Observable<never> => {
    localStorage.clear();
    const decodedToken = this.jwtService.decodeToken(data.token);
    localStorage.setItem(AuthService.USER_ROLE, decodedToken.roles);
    this.setAuthToken(data.token);
    return EMPTY;
  };
}
