export const environment = {
  production: false,
  BASE_URL: 'https://dev.menteno.com/core',
  IMAGES_PUBLIC_URL: 'https://dev.menteno.com/public/images/',
  firebase: {
    apiKey: "AIzaSyDu1pg-k_HnJO1h6ZRQwi6jGr7zXyMdRIM",
    authDomain: "menteno-305c2.firebaseapp.com",
    projectId: "menteno-305c2",
    storageBucket: "menteno-305c2.appspot.com",
    messagingSenderId: "68019123290",
    appId: "1:68019123290:web:2a0ea6c9dbaf640cc95463",
  }
};
